    // // Local
    // const protocol = "https";
    //       const host = "f1f7-2405-201-200d-1c68-6006-7286-4905-eab9.ngrok-free.app/enovltec/api/v1";
  
        
          // Live

          const protocol = "https";
          const host = "api.enovltec.rejoicehub.com/api/v1/";
const port = "";
const trailUrl = "";

const hostUrl = `${protocol}://${host}${port ? ":" + port : ""}`;
const endpoint = `${protocol}://${host}${port ? ":" + port : ""}${trailUrl}`;

export default {
  protocol: protocol,
  host: host,
  port: port,
  apiUrl: trailUrl,
  endpoint: endpoint,
  hostUrl: hostUrl,
};
